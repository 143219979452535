import styled from 'styled-components';

import { palette } from 'design-system/theme';

export const Container = styled.button`
  border-radius: 10px;
  background-color: ${palette.shades.white};
  color: ${palette.typography[100]};
  border-radius: 44px;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
`;
