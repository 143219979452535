import styled from 'styled-components';

import { palette } from 'design-system/theme';

interface ContainerProps {
    type?: 'success' | 'danger'
}

const containerBackround : Record<string, string> = {
	'success': palette.success[100],
	'danger': palette.error[100],
};

export const Container = styled.div<ContainerProps>`
    display: flex;
    height: 20px;
    padding: 0px 6px 1px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100px;
    border: 2px solid #FFF;
    background: ${props =>  props.type ? containerBackround[props.type] : containerBackround['success']};
    margin-top: 5px;
`;

export const Text = styled.label`
    color: #FFF;
    text-align: center;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 15.6px */
    letter-spacing: 0.325px;
`;
