import React from 'react';

import { icons } from 'assets';

import { Image } from './styles';
import { type IconProps } from './types';

const Icon: React.FC<IconProps>  = ({ name, size }: IconProps) => {
	const source = icons[name]();
	return (
		<Image
			src={source}
			size={size}
		/>
	);
};

export default Icon;
