import axios from 'axios';

import api from 'services/api';

import { BinancePrice, MarketResponse, SearchPairAPIParams } from './types';

const CalculatorModule = {
	getMarketPrice: ({ value }: SearchPairAPIParams) => api.get<MarketResponse>(`/market/price?pair=${value}`).then(({data}) => data),
	getBinancePrice: () => axios.get<BinancePrice>(`${process.env.REACT_APP_BINANCE_URL}/ticker/price?symbol=USDTBRL`).then(({data}) => data),
	getBitgetPrice: () => axios.get<{data: {sellOne: string}}>(`${process.env.REACT_APP_BITGET_URL}/market/ticker?symbol=USDTBRL_SPBL`).then(({data}) => data.data.sellOne),
	getKucoinPrice: () => axios.get<{data: {price: string}}>('https://cors.capitual.net/?https://api.kucoin.com/api/v1/market/orderbook/level1?symbol=USDT-BRL').then(({data}) => data.data.price),
	getBitprecoPrice: () => axios.get<{sell: string}>(`${process.env.REACT_APP_BITPRECO_URL}/usdt-brl/ticker`).then(({data}) => data.sell),
	getMercadoBitcoinPrice: () => axios.get<Array<{sell: string}>>(`${process.env.REACT_APP_MERCADOBITCOIN_URL}/tickers?symbols=USDT-BRL`).then(({data}) => data[0].sell),
	getHuobiPrice: () => axios.get<{tick: { ask: Array<string> }}>(`${process.env.REACT_APP_HUOBI_URL}/market/detail/merged?symbol=usdtbrl`).then(({data}) => data.tick.ask[0] ?? '')

};

export default CalculatorModule;
