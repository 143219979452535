import styled from 'styled-components';

import { fontName, palette, theme } from 'design-system/theme';

import { type OptionProps } from './types';

export const Container = styled.div`
    margin-bottom: 10px;
    position: relative;
`;

export const Content = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    height: 60px;
    position: relative;
    align-items: center;
    display: flex;
    cursor: pointer;
    &:hover {
        background-color: ${palette.grays[100]};
    }
`;

export const Text = styled.div`
    font-size: ${theme.fonts.$6};
    font-family: ${fontName};
    font-weight: 500;
    line-height: 22px;
    margin-left: 75px;
`;

export const IconRight = styled.div`
    position: absolute;
    right: 16px;
    top: 20px;
    pointer-events: none;
`;

export const IconLeft = styled.div`
    position: absolute;
    top: 10px;
    left: 20px;
    pointer-events: none;
`;

export const Options = styled.ul`
    width: 100%;
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.10);
    padding: 0;
    background-color: white;
    border-radius: 8px;
    background-color: ${palette.shades.white};
    position: absolute;
    top: 48px;
    z-index: 9999;


`;

export const Option = styled.li<OptionProps>`
    padding: 10px 0px;
    font-size: ${theme.fonts.$5};
    font-family: ${fontName};
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    opacity: ${props => props.disable ? 0.4 : undefined};
    &:hover {
        background-color: ${palette.grays[100]};
    }
`;

export const ContainerOption = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;
export const ContentIcon = styled.div`
    padding: 10px 0px;
    margin-left: 16px;
`;

export const ContentIconRight = styled.div`
    padding: 10px 0px;
    margin-right: 16px;
`;

export const Divider = styled.div`
    background: rgba(39, 41, 55, 0.05);
    width: 88%;
    height: 1px;
    float: right;
    display: flex;
`;

export const ContentOption = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    /* padding: 16px 0px; */
    justify-content: space-between;
`;

export const Soon = styled.div`
    background-color: ${palette.typography[200]};
    border-radius: 16px;
    padding: 0px 6px 1px 6px;

`;
export const SoonText = styled.label`
    color: ${palette.shades.white};
    font-size: ${theme.fonts.$4};
    font-family: ${fontName};
`;
