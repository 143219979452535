import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Calculator from 'screens/public/calculator';

const routes: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Calculator /> } />
			</Routes>
		</BrowserRouter>
	);
};

export default routes;
