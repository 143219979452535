import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';

import { doNothing } from 'constants/function';

import Icon from 'components/common/icons';

import { Container, ContainerOption, Content, ContentIcon, ContentIconRight, ContentOption, Divider, IconLeft, IconRight, Option, Options, Soon, SoonText, Text } from './styles';
import { type SelectProps } from './types';

const Select: React.FC<SelectProps> = ({ options, onSelect }: SelectProps) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [selected, setSelected] = useState(options[0]);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const { t } = useTranslation('calculatorScreen');

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node))
				setIsDropdownOpen(false);
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};
    
	// eslint-disable-next-line
	const toggleSelect = (item: any) => {
		onSelect(item);
		setSelected(item);
		setIsDropdownOpen(false);
	};

	return (
		<Container ref={dropdownRef}>
			<Content onClick={toggleDropdown}>
				<IconLeft>
					<Icon name={selected.icon} />
				</IconLeft>
				<IconRight>
					<Icon name='arrow-down' />
				</IconRight>
				<Text key={selected.value}> {selected.text} </Text>
			</Content>
			{
				isDropdownOpen &&
                <Options>
                	{
                		options.map(el => (
                			<>

                				<Option key={el.value} onClick={() => { el.disable ? doNothing() : toggleSelect(el); }} disable={el.disable}>
                					<ContainerOption>
                						<ContentIcon>
                							<Icon name={el.icon}/>
                						</ContentIcon>
                						<ContentOption>
                							<Option> {el.text} </Option>
                							<ContentIconRight>
                								<If condition={!el.disable}>
                									<Then>
                										<If condition={selected.value === el.value}>
                											<Then>
                												<Icon name="check" />
                											</Then>
                											<Else>
                												<Icon name="arrow-right" />
                											</Else>
                										</If>
                									</Then>
                									<Else>
                										<Soon>
                											<SoonText> {t('soon')} </SoonText>
                										</Soon>
                									</Else>
                								</If>

                							</ContentIconRight>
                						</ContentOption>
                					</ContainerOption>
                				</Option>
                				<Divider />
                			</>
                		))
                	}

                </Options>
			}
		</Container>
	);
};

export default Select;
