import styled from 'styled-components';

export const Container = styled.div`
    flex-direction: column;
    width: 46%;
    position: relative;
`;

export const Content = styled.div`
    margin-top: 10px;
`;

export const IconContent = styled.div`
    position: absolute;
    right: 0;
    bottom: 10px;
`;
