import styled from 'styled-components';

import { fontName, palette, theme } from 'design-system/theme';

export const Container = styled.div`
    /* border: 1px solid black; */
    padding: 24px 0px 0px 0px;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const ExchangeName = styled.label` 
    font-size: ${theme.fonts.$4};
    /* font-family: ${fontName}; */
    font-family: 'Helvetica Now Display', sans-serif;
    font-weight: 500;
    color: ${palette.typography[300]};
    line-height: 22px;
    justify-self: center;
`;

export const Price = styled.label` 
    font-size: ${theme.fonts.$6};
    font-family: ${fontName}; 
    font-weight: 500;
    color: ${palette.shades.black};
`;