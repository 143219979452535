import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ExchangePrice from 'components/calculator/exchang-price';

// import Icon from 'components/common/icons';
import { Container, Content, Divider, Header, ScrollView, Title } from './styles';
import { ExchangesProps } from './types';

const Exchanges: React.FC<ExchangesProps> = ({ exchanges }: ExchangesProps) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const scrollRef: any= useRef(null);

	// const [isDragging, setIsDragging] = useState(false);
	// const [startX, setStartX] = useState(0);
	// const [scrollLeft, setScrollLeft] = useState(0);
	const { t } = useTranslation('calculatorScreen');

	// const handleScrollLeft = () => {
	// 	if (scrollRef.current) 
	// 		scrollRef.current.scrollLeft -= 10;
	// };
  
	// const handleScrollRight = () => {
	// 	if (scrollRef.current) 
	// 		scrollRef.current.scrollLeft += 10;
	// };
 

  	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// const handleMouseDown = (e: any) => {
	// 	setIsDragging(true);
	// 	setStartX(e.pageX - containerRef.current.offsetLeft);
	// 	setScrollLeft(containerRef.current.scrollLeft);
	// };
  
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// const handleMouseMove = (e: any) => {
	// 	if (!isDragging) return;
	// 	const x = e.pageX - containerRef.current.offsetLeft;
	// 	const walk = (x - startX) * 4; 
	// 	containerRef.current.scrollLeft = scrollLeft - walk;
	// };
  
	// const handleMouseUp = () => {
	// 	setIsDragging(false);
	// };

	return (
		<Container>
			<Divider />
			<Header>
				<Title> {t('exchanges.title')} </Title>
			</Header>
			<Content>
				<ScrollView
					ref={scrollRef}
					// onMouseDown={handleMouseDown}
					// onMouseMove={handleMouseMove}
					// onMouseUp={handleMouseUp}
				>
					{
						[...exchanges, ...exchanges].map((exchange, i) => (
							<ExchangePrice  key={i} exchange={exchange.name} price={exchange.price}/>
						))
					}
				</ScrollView>
			</Content>
			{/* <ContentIcon position='left' onClick={handleScrollLeft}>
				<Icon name='arrow-left' />
			</ContentIcon>
			<ContentIcon position='right' onClick={handleScrollRight}>
				<Icon name='arrow-right' />
			</ContentIcon> */}
		</Container>
	);
};

export default Exchanges;