import styled from 'styled-components';

import { palette, theme } from 'design-system/theme';

export const Container = styled.div`
    position: absolute;
    border-radius: 8px;
    width: 180px;
    padding: 12px 20px 12px 20px;
    gap: 8px;
    background-color: ${palette.typography[100]};
    /* position: relative; */
    top: -60px;
    right: -80px;
    justify-content: center;
    align-items: center;
`;

export const Indicator = styled.div`
    width: 0; 
    height: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    position: absolute;
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;
    border-top: 22px solid ${palette.typography[100]};
`;

export const Label = styled.label`
    color: ${palette.shades.white};
    font-size: ${theme.fonts.$4};
    font-weight: 500;
`;
