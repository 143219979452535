import { currencyFormat } from 'utils/currency';

import { Container, ExchangeName, Price } from './styles';
import { ExchangePriceProps } from './types';

const ExchangePrice: React.FC<ExchangePriceProps> = ({ exchange, price }: ExchangePriceProps) => {
	return (
		<Container>
			<ExchangeName> {exchange} </ExchangeName>
			<Price>  {currencyFormat(price)}</Price>
		</Container>
	);
};

export default ExchangePrice;