import React from 'react';

import Icon from 'components/common/icons';

import { Container } from './styles';
import { type ButtonProps } from './types';

const Button: React.FC<ButtonProps> = ({ text, onClick }: ButtonProps) => {
	return (
		<Container onClick={onClick}>
			<Icon
				name='copy'
			/>
			{text}
		</Container>
	);
};

export default Button;
