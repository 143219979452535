import styled from 'styled-components';

import { fontName, MAX_WIDTH_SM, palette, theme } from 'design-system/theme';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: black; */
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(5px);
`;

export const Content = styled.div`
    /* z-index: 9999; */
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    gap: 8px;
    @media (max-width: ${MAX_WIDTH_SM}px) {
      width: 80%;
    }
    /* height: 80%; */
`;

export const ContentIcon = styled.div`
`;

export const Title = styled.label`
    text-align: center;
    font-size: ${theme.fonts.$8};
    font-family: ${fontName};
    font-weight: 600;
    color: ${palette.typography[100]};
    line-height: 22px;
    margin-top: 16px;
    justify-self: center;
    line-height: 30px;
`;

export const Description = styled.label`
    text-align: center;
    font-size: ${theme.fonts.$6};
    font-family: 'Helvetica Now Display', sans-serif;
    font-weight: 500;
    color: ${palette.typography[100]};
    line-height: 22px;
    margin-top: 16px;
    justify-self: center;
`;