import styled, { keyframes } from 'styled-components';

import { palette } from 'design-system/theme';

const jump = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
`;


export const LoadingBallsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  position: absolute;
`;

export const LoadingBall = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${palette.typography[300]};
  border-radius: 50%;
  margin: 0 10px;
  animation: ${jump} 1s infinite;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;