import styled from 'styled-components';

import { MAX_WIDTH_SM } from 'design-system/theme';

export const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 20px;

`;

export const Header = styled.div` 
    margin-bottom: 20px;
`;

export const ContentTitle = styled.div`
    flex-direction: row;
    gap: 10px;
    display: flex;
    align-items: center;
`;

export const Center = styled.div`
    margin-top: 40px;
`;

export const ContentLogo = styled.div`
    flex-direction: row;
    gap: 20px;
`;

export const InputContent = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const LogoContent = styled.img`

`;
export const LogoCenter = styled.img`
    margin-left: 14px;
`;

export const CopyContent = styled.div`
   position: relative;
`;

export const Footer = styled.div`
    width: 100%;
    margin-top: 10px;
    display: relative;
`;

export const PriceContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    @media (max-width: ${MAX_WIDTH_SM}px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
`;