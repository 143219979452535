import styled from 'styled-components';

import { fontName, palette, theme } from 'design-system/theme';

export const Container = styled.input`
    width: 100%;
    border: none;
    border-bottom: 1px solid ${palette.grays[300]};
    outline: none;
    font-size: ${theme.fonts.$6};
    font-family: ${fontName};
    padding-bottom: 10px;
    padding-top: 10px;
    :focus {
        outline: none;
    }
`;
