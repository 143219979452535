import styled from 'styled-components';

export const fontName = 'Aeonik';

export const palette = {
	typography: {
		100: '#272937',
		200: '#0264DC',
		300: '#026FF4',
	},
	grays: {
		100: '#F8F9FB',
		200: '#757893',
		300: '#9EA1B9',
		400: '#F3F4F9',
	},
	shades: {
		white: '#FFFFFF',
		black: '#000000'
	},
	success: {
		100: '#3AAC86',
	},
	error: {
		100: '#C85B69',
	}
};

export const sizes = {
	$1: '20%',
	$2: '25%',
	$3: '30%',
	$4: '35%',
	$5: '40%',
	$6: '45%',
	$7: '50%',
	$8: '55%',
	$9: '60%',
	$10: '65%',
	$11: '70%',
	$12: '75%',
	$13: '80%',
	$14: '85%',
	$15: '90%',
	$16: '95%',
	$17: '100%'
};

export const MAX_WIDTH_SM = 440;
export const MAX_WIDTH_MD = 740;
export const MAX_WIDTH_LG = 1000;

export const lightColors = {
	$black: palette.shades.black,
	$white: palette.shades.white,
	$transparent: 'transparent'
};

export const theme = {
	fonts: {
		$0: '8px',
		$1: '10px',
		$3: '12px',
		$4: '14px',
		$5: '16px',
		$6: '18px',
		$7: '20px',
		$8: '22px',
		$9: '24px',
		$10: '26px',
		$11: '28px',
		$12: '30px',
		$13: '32px',
		$14: '34px',
		$15: '36px'
	}
};

export const Container = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;
    border: 1px solid red;
    background-color: ${palette.shades.white};
    justify-content: center;
    align-items: center;
  `;

export const Title = styled.h3`
    color: ${palette.typography[100]};
    font-family: ${fontName};
    font-size: 26px;
    font-weight: 500;
  `;

export const Text = styled.label`
    color: ${palette.typography[100]};
    font-family: ${fontName};
    font-size: ${theme.fonts.$6};
    font-weight: 500;
  `;

export const Price = styled.label`
    color: ${palette.typography[200]};
    font-family: ${fontName};
    font-size: ${theme.fonts.$13};
    font-weight: 500;
  `;

const darkColors = {
	...lightColors
};

const themeDark = {
	...theme,
	colors: darkColors
};

export { themeDark };