import React from 'react';

import { Text } from 'design-system/theme/theme';

import Icon from 'components/common/icons';
import Input from 'components/common/input';

import { Container, Content, IconContent } from './styles';
import { type InputCalculatorProps } from './types';

const InputCalculator: React.FC<InputCalculatorProps> = ({ 
	title, icon, value, onChange, readOnly = false, placeholder = ''
}: InputCalculatorProps) => {
	return (
		<Container>
			<Text> {title} </Text>
			<Content>
				<Input 
					value={value} 
					onChange={onChange} 
					placeholder={placeholder}
					readOnly={readOnly} 
				/>
				{
					!!icon &&
                    <IconContent>
                  	    <Icon name={icon}/>
                    </IconContent>
				}
			</Content>
		</Container>
	);
};

export default InputCalculator;
