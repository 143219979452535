import { useTranslation } from 'react-i18next';
import { When } from 'react-if';

import Icon from 'components/common/icons';

import { Container, Content, ContentIcon, Description, Title } from './styles';
import { MarketDownProps } from './types';

const MarketDown: React.FC<MarketDownProps> = ({ error }: MarketDownProps) => {
	const { t } = useTranslation('calculatorScreen');


	return (
		<Container>
			<Content>
				<ContentIcon>
					<Icon name='alert' size={40}/>
				</ContentIcon>
				<Title>
					<When condition={error === 'offine'}>
						{t('offlineMarket.title')}
					</When>
					<When condition={error === 'quote'}>
						{t('quoteError.title')}
					</When>
				</Title> 
				<Description>
					<When condition={error === 'offine'}>
						{t('offlineMarket.description')} 
					</When>
					<When condition={error === 'quote'}>
						{t('quoteError.description')} 
					</When>
				</Description>
			</Content>
		</Container>
	);
};

export default MarketDown;