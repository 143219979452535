import { LoadingBall, LoadingBallsContainer } from './styles';

const Loading: React.FC = () => {
	return (
		<LoadingBallsContainer>
			<LoadingBall />
			<LoadingBall />
			<LoadingBall />
		</LoadingBallsContainer>
	);
};

export default Loading;