import Loading from 'components/common/loading';

import { Container } from './styles';

const LoadingCard: React.FC = () => {
	return (
		<Container>
			<Loading />
		</Container>
	);
};

export default LoadingCard;