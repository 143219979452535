import c from 'currency.js';

const allowedCurrencies = {
	BRL: {
		code: 'BRL',
		symbol: 'R$ ',
		precision: 4,
		separator: ',',
		decimal: '.'
	}
};

export const currencyFormat = (value: string) => {
	return (c(value, { fromCents: false, precision: 4}).format({ ...allowedCurrencies.BRL }));
};
