import styled from 'styled-components';

import { MAX_WIDTH_MD, palette, sizes } from 'design-system/theme/theme';
import { type ThemeSize } from 'design-system/theme/types';

interface ContainerProps {
  padding?: number
  backgroundColor?: string
  size?: ThemeSize
  type: 'primary' | 'secundary'
  isfull?: boolean
}

const background = {
	primary: palette.shades.white,
	secundary: palette.grays[100]
};

export const Container = styled.div<ContainerProps>`
  padding: ${props => props.padding ? props.padding : 32}px;
  background-color: ${props => background[props.type]};
  border: ${props => props.type === 'primary' ? '1px solid rgba(0, 0, 0, 0.10);' : undefined};
  width: ${props => props.size ? sizes[props.size] : '50%'};
  border-radius: 16px;
  box-sizing: border-box;
  max-width: 600px;
  position: relative;
  @media (max-width: ${MAX_WIDTH_MD + 100}px) {
    width: ${props => !props.isfull && sizes.$15};
  }
`;
