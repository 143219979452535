import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { Container, Indicator, Label } from './styles';
import { type ToastProps } from './types';

const Toast: React.FC<ToastProps> = ({ text, isVisible = false }: ToastProps) => {
	const [visible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(isVisible);
	}, [isVisible]);

	useEffect(() => {
		if (visible) {
			const timer = setTimeout(() => {
				setIsVisible(false);
			}, 2000);

			return () => { clearTimeout(timer); };
		}
	}, [visible]);

	const fade = useSpring({
		opacity: visible ? 1 : 0
	});

	return (
		<animated.div style={fade}>
			<Container>
				<Label> {text} </Label>
				<Indicator />
			</Container>
		</animated.div>

	);
};

export default Toast;
