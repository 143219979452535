import React from 'react';

import { Container } from './styles';
import { type CardProps } from './types';

const Card: React.FC<CardProps> = ({ children, size = '$10', type = 'primary', isfull = false }: CardProps) => {
	return (
		<Container size={size} isfull={isfull} type={type}>
			{children}
		</Container>
	);
};

export default Card;
