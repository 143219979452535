import {useQuery} from '@tanstack/react-query';

import CalculatorModule from '.';

const GET_MARKET_PRICE = 'getMarketPrice';
const GET_BINANCE_PRICE = 'getBinancePrice';
const GET_BITGET_PRICE = 'getBitget';
const GET_KUCOIN_PRICE = 'getKucoin';
const GET_BITPRECO_PRICE = 'getBitPreco';
const GET_MERCADOBITCOIN_PRICE = 'getMercadoBitcoinPrice';
const GET_HUOBI_PRICE = 'getHuobiPrice';


export const useGetMarketPrice = (value: string) =>
	useQuery({
		queryKey:[GET_MARKET_PRICE],
		queryFn: () => CalculatorModule.getMarketPrice({value: value}),
	});
export const useGetBinancePrice = () =>
	useQuery({
		queryKey:[GET_BINANCE_PRICE],
		queryFn: (CalculatorModule.getBinancePrice),
	});
export const useGetBitgetPrice = () =>
	useQuery({
		queryKey:[GET_BITGET_PRICE],
		queryFn: (CalculatorModule.getBitgetPrice),
	});
export const useGetKucoinPrice = () =>
	useQuery({
		queryKey:[GET_KUCOIN_PRICE],
		queryFn: (CalculatorModule.getKucoinPrice),
	});
export const useGetBitprecoPrice = () =>
	useQuery({
		queryKey:[GET_BITPRECO_PRICE],
		queryFn: (CalculatorModule.getBitprecoPrice),
	});
export const useGetMercadoBitcoinPrice = () =>
	useQuery({
		queryKey:[GET_MERCADOBITCOIN_PRICE],
		queryFn: (CalculatorModule.getMercadoBitcoinPrice),
	});
export const useGetHuobiPrice = () =>
	useQuery({
		queryKey:[GET_HUOBI_PRICE],
		queryFn: (CalculatorModule.getHuobiPrice),
	});