import { Container, Text } from './styles';
import { TagProps } from './types';

const Tag: React.FC<TagProps> = ({text, type}: TagProps) => {
	return (
		<Container type={type}>
			<Text> {text} </Text>
		</Container>
	);
};

export default Tag;