import { type IconName } from 'assets/types';

interface Currency {
  name: string
  icon: IconName
  code: string
  disbled: boolean
}
export const currencies: Currency[] = [{
	name: 'Tether (USDT)',
	icon: 'usdt',
	code: 'USDT',
	disbled: false
}, {
	name: 'USD Coin (USDC)',
	icon: 'usdc',
	code: 'USDC',
	disbled: true
}, {
	name: 'Bitcoin (BTC)',
	icon: 'btc',
	code: 'BTC',
	disbled: true
}];
