import alert from 'assets/icons/alert/alert.svg';
import arrowDown from 'assets/icons/arrow-down/arrow-down.svg';
import arrowLeft from 'assets/icons/arrow-left/arrow-left.svg';
import arrowRight from 'assets/icons/arrow-right/arrow-right.svg';
import btc from 'assets/icons/btc/btc.svg';
import capLogo from 'assets/icons/cap-logo/cap-logo.svg';
import check from 'assets/icons/check/check.svg';
import copy from 'assets/icons/copy/copy.svg';
import percent from 'assets/icons/percent/percent.svg';
import usdc from 'assets/icons/usdc/usdc.svg';
import usdt from 'assets/icons/usdt/usdt.svg';

import { type IconName, type ImageRequireSources } from './types';

const icons: ImageRequireSources<IconName> = {
	copy: () => copy,
	usdt: () => usdt,
	percent: () => percent,
	'arrow-down': () => arrowDown,
	usdc: () => usdc,
	'arrow-right': () => arrowRight,
	'arrow-left': () => arrowLeft,
	check: () => check,
	btc: () => btc,
	'cap-logo': () => capLogo,
	alert: () => alert,
};

export { icons };
