import styled, { keyframes } from 'styled-components';

import { fontName, palette, theme } from 'design-system/theme';

export const scrollAnimation = keyframes`
  to {
    transform: translateX(calc(-50% - 0.8rem));
  }
`;
interface ContentIconProps {
    position: 'left' | 'right'
}

export const Container = styled.div`
    position: relative;
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
`;

export const Title = styled.label` 
    text-align: center;
    font-size: ${theme.fonts.$4};
    /* font-family: ${fontName}; */
    font-family: 'Helvetica Now Display', sans-serif;

    font-weight: 500;
    color: ${palette.grays[200]};
    line-height: 22px;
    margin-top: 16px;
    justify-self: center;
`;

export const Content = styled.div` 
    width: 96%;
    margin-left: 10px;
    margin-right: 20px;
    overflow: hidden;
`;

export const ScrollView = styled.div`  
    flex-direction: row;
    overflow: hidden;
    gap: 32px;
    cursor: pointer;
    display: flex;
    width: fit-content; 
    animation: ${scrollAnimation} 20s linear infinite;
    white-space: nowrap;
    -ms-overflow-style: none;
    scroll-behavior: auto; 
    box-sizing: border-box;

    &:hover {
        animation-play-state: paused;
    }
    /* width: 100%; */ 

    /* left: 100%; */
    /* For Webkit (Chrome, Safari) */
    &::-webkit-scrollbar {
        width: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
    /* For Firefox */
    scrollbar-width: thin;
    /* For IE/Edge */
    -ms-overflow-style: none;
`;
// export const Slide = styled.div`
//     display: inline-block;
//     animation: ${scrollAnimation} 5s linear infinite;
// `;
export const ContentIcon = styled.button<ContentIconProps>`
    display: flex;
    width: 32px;
    height: 32px; 
    border-radius: 200px;
    background-color: ${palette.grays[400]};
    justify-content: center;
    align-items: center;
    position: absolute;
    border: none;
    left: ${props => props.position === 'left' ? -14 : undefined}px;
    right: ${props => props.position === 'right' ? -14 : undefined}px;
    bottom: 10px;
    cursor: pointer;
`;

export const Divider = styled.div`
    background: rgba(0, 0, 0, 0.10);
    width: 100%;
    height: 1px;
    display: flex;
    position: absolute;
`;