import { QueryClientProvider } from '@tanstack/react-query';

import {client} from 'services/api/query-client';

import RoutesComponent from './routes';

const App: React.FC = () => {
	return (
		<QueryClientProvider client={client}>
			<RoutesComponent />
		</QueryClientProvider>
	);
};

export default App;