import React from 'react';

import { Container } from './styles';
import { type InputProps } from './types';

// eslint-disable-next-line
const Input: React.FC<InputProps> = React.forwardRef<HTMLInputElement, InputProps>(
	({ type, readOnly = false, ...rest }, ref): JSX.Element => {
		return (
			<Container
				ref={ref}
				readOnly={readOnly}
				type={type}
				{...rest}
			/>
		);
	});

export default Input;
