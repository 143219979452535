import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams,useNavigate, useSearchParams } from 'react-router-dom';

import { Price, Text, Title } from 'design-system/theme/theme';

import { currencyFormat } from 'utils/currency';
import { calculateBase } from 'utils/operations';

import { useGetBinancePrice, useGetBitgetPrice, useGetBitprecoPrice, useGetHuobiPrice, useGetKucoinPrice, useGetMarketPrice, useGetMercadoBitcoinPrice } from 'services/api/modules/calculator/queries';

import { currencies } from 'data/currencies';

import Exchanges from 'components/calculator/exchanges';
import InputCalculator from 'components/calculator/input';
import LoadingCard from 'components/calculator/loading-card';
import MarketDown from 'components/calculator/market-down';
import Button from 'components/common/button';
import Card from 'components/common/card';
import Icon from 'components/common/icons';
import Select from 'components/common/select';
import Tag from 'components/common/tag';
import Toast from 'components/common/toast';

import { Center, Container, ContentLogo, ContentTitle, CopyContent, Footer, Header, InputContent, PriceContent } from './styles';
import { ExchangePrice } from './types';

const Calculator: React.FC = () => {
	const [searchParams] = useSearchParams();
	const currParam = searchParams.get('pair');
	const spredParam = searchParams.get('spread');

	const { data, refetch, isLoading} = useGetMarketPrice(currParam ? `${currParam}_BRL` : 'USDT_BRL');
	const { data: binancePrice, refetch: refetchBinancePrice } = useGetBinancePrice();
	const { data: bitgetPrice, refetch: refetchBitgetPrice } = useGetBitgetPrice();
	const { data: kucoinPrice, refetch: refetchKucoinPrice } = useGetKucoinPrice();
	const { data: bitprecoPrice, refetch: refetchBitprecoPrice } = useGetBitprecoPrice();
	const { data: mercadoBitcoinPrice, refetch: refetchMercadoBitcoinPrice } = useGetMercadoBitcoinPrice();
	const { data: huobiPrice, refetch: refetchHuobiPrice } = useGetHuobiPrice();

	const navigate = useNavigate();

	const [price, setPrice] = useState('0');

	const { t } = useTranslation('calculatorScreen');
	const [copied, setCopied] = useState(false);

	const [values, setValues] = useState({
		base: '10000',
		spread: '',
		currency: ''
	});

	const handleChangeValues = (
		id: 'base' | 'spread' | 'currency',
		value: string,
	) => {
		setValues(p => ({
			...p,
			[id]: value
		}));
	};

	useEffect(() => {  
		if(data?.data?.fxRate) {
			const basePrice = calculateBase(data.data.fxRate, values.spread);
			setPrice(String(basePrice));
		}
	}, [handleChangeValues, data, values]);


	const copy = useCallback((value: string) => {
		navigator.clipboard.writeText(value);
		setCopied(false);
		const timer = setTimeout(() => {
			setCopied(true);
		}, 100);
		return () => { clearTimeout(timer); };
	}, [setCopied]);

	const selectCurrency = (code: string) => {
		navigate({
			pathname: '/',
			search: createSearchParams({
				pair: code,
			}).toString()
		});
		refetch();
	};

	useEffect(() => {
		if(spredParam) 
			handleChangeValues('spread', spredParam);
	}, [spredParam]);

	useEffect(() => {
		const timer = setInterval(() => {
			Promise.all([
				refetch(),
				refetchBinancePrice(),
				refetchBitgetPrice(),
				refetchBitprecoPrice(),
				refetchKucoinPrice(),
				refetchMercadoBitcoinPrice(),
				refetchHuobiPrice(),
			]);
		}, 4000);
		return () => clearInterval(timer); 
	}, [refetch, refetchBinancePrice, refetchBitgetPrice, refetchBitprecoPrice, refetchKucoinPrice, refetchMercadoBitcoinPrice]);
 
	const isMarketClosed = !!data?.error;

	const exchangePrices: ExchangePrice[] = [{
		name: 'Binance',
		price: binancePrice?.price ?? '',
	}, {
		name: 'Bitget',
		price: bitgetPrice ?? '',
	}, {
		name: 'Kucoin',
		price: kucoinPrice ?? ''
	}, {
		name: 'BityPreço',
		price: bitprecoPrice ?? ''
	}, {
		name: 'Mercado Bitcoin',
		price: mercadoBitcoinPrice ?? '',
	}, {
		name: 'Huobi',
		price: huobiPrice ?? ''
	}];


	const handleChangeSpread = (value: string) => {
		searchParams.set('spread', value);
		handleChangeValues('spread', value.replace(',', '.').replace(/[^0-9.]/g, '')); 
		navigate({
			search: createSearchParams({
				spread: value,
			}).toString()
		});
	};

	return (
		<Container>
			<Card size="$5">
				<Header>
					<ContentLogo>
						<Icon 
							name='cap-logo'
						/>
					</ContentLogo>
					<ContentTitle>
						<Title> {t('title')} </Title>
						<Tag text={data?.data?.market ? t('onMarket'): t('offMarket')} type={data?.data?.market ? 'success' : 'danger'}/>
					</ContentTitle>
				</Header>
				<Center>
					<Select options={currencies.map(c => {
						return {
							text: c.name,
							icon: c.icon,
							value: c.code,
							disable: c.disbled
						};
					})}
					onSelect={(item) => { selectCurrency(item.value); }}/>
					<InputContent>
						<InputCalculator 
							title={t('base')} 
							value={currencyFormat(data?.data?.fxRate || '')} 
							readOnly={true}
						/>
						<InputCalculator 
							title={t('spread')} 
							icon='percent' 
							value={values.spread} 
							placeholder="0.0"
							onChange={(e) => handleChangeSpread(e.target.value)}/>
					</InputContent>
				</Center>
				<Footer>
					<Card isfull type="secundary" size="$17">
						<Text> {t('coinPrice', { coin: 'USDT' })} </Text>
						<PriceContent>
							<Price> {currencyFormat(price)} </Price>
							<CopyContent>
								<Toast isVisible={copied} text={t('clipboardCopy')}/>
								<Button text={t('copy')} onClick={() => copy(price)}/>
							</CopyContent>
						</PriceContent>
					</Card>
				</Footer>
				<Exchanges  exchanges={exchangePrices}/>
				<>
					{
						isMarketClosed && <MarketDown error={data?.error === 'Not business hours' ? 'offine' : 'quote'} />
					}
					{
					 	isLoading && <LoadingCard />

					}
				</>
			</Card>
		</Container>
	);
};

export default Calculator;
