import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import en from 'locales/en';
import pt from 'locales/pt';

import 'intl-pluralrules';
// import { doNothing } from "constants/function";

export const defaultNS = 'commom';

export const resources = {
	en,
	pt
};

i18n
	.use(initReactI18next)
	.init({
		defaultNS,
		resources,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		}
		// debug: __DEV__,
	})
	.finally(() => {}).catch(err => console.log('Err', err));
